"use strict";
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
// Import our custom CSS
import '../scss/login-page.scss';
import { Container, Row, Col, Stack, Image, Alert, Button, FloatingLabel, Form } from 'react-bootstrap';
function goTo(path) {
    if (path)
        window.location.replace(path);
    else
        window.location.reload();
}
function ResetPage() {
    var _a = React.useState(false), isDone = _a[0], setIsDone = _a[1];
    var _b = React.useState(''), email = _b[0], setEmail = _b[1];
    function reset() {
        if (email != '') {
            var formData = new FormData();
            formData.append('email', email);
            fetch('/admin/api/reset-password', { method: "POST", body: formData }).then(function (data) { return data.json(); }).then(function (res) {
                setIsDone(true);
            }).catch(function (e) { return console.error(e.message); });
        }
    }
    return React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(Row, null,
                React.createElement(Col, { sm: 8, md: 6, lg: 5, xl: 4, xxl: 3, className: 'mx-auto' },
                    React.createElement("div", { className: 'spacer-150' }),
                    React.createElement(Stack, { className: 'bg-white text-black rounded p-3 my-auto align-items-center', direction: 'vertical', gap: 5 },
                        React.createElement("h1", null, "Reset Password"),
                        React.createElement(Image, { width: 70, height: 70, src: '/images/twin-logo-white.svg' }),
                        React.createElement(Stack, { gap: 2, className: 'mx-3' },
                            React.createElement(FloatingLabel, { controlId: "floatingInput", label: "Email address" },
                                React.createElement(Form.Control, { value: email, type: "email", placeholder: "name@example.com", className: 'bg-white text-black', onChange: function (e) { return setEmail(e.target.value); }, disabled: isDone }))),
                        React.createElement("small", null, "If your email address is found in the system, email will be sent with a temporary password."),
                        React.createElement(Button, { className: 'py-2 px-5 pill-shape', disabled: isDone, onClick: reset }, "RESET"),
                        React.createElement("small", { className: 'clickable', onClick: function () { return goTo('/'); } }, "Back to Login page"))))),
        React.createElement(Alert, { show: isDone, className: 'bg-success fixed-top', dismissible: true, onClose: function () { setIsDone(false); } },
            React.createElement(Alert.Heading, null, "Done!"),
            React.createElement("p", null, "Request is made. Please check your email.")));
}
function LoginPage(props) {
    var _a = React.useState(false), isError = _a[0], setIsError = _a[1];
    var _b = React.useState(false), isTemp = _b[0], setIsTemp = _b[1];
    var _c = React.useState(''), email = _c[0], setEmail = _c[1];
    var _d = React.useState(''), password = _d[0], setPassword = _d[1];
    function login() {
        if (email != '' && password != '') {
            var formData = new FormData();
            formData.append('email', email);
            formData.append('password', password);
            fetch('/admin/api/login', { method: "POST", body: formData }).then(function (data) { return data.json(); }).then(function (res) {
                if (res.error) {
                    console.log('login error:', res.error);
                    setIsError(true);
                }
                else if (res.isTemporary)
                    setIsTemp(true);
                else
                    goTo(nextPage);
            }).catch(function (e) { return console.error(e.message); });
        }
    }
    return React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(Row, null,
                React.createElement(Col, { sm: 8, md: 6, lg: 5, xl: 4, xxl: 3, className: 'mx-auto' },
                    React.createElement("div", { className: 'spacer-150' }),
                    React.createElement(Stack, { className: 'bg-white text-black rounded p-3 my-auto align-items-center', direction: 'vertical', gap: 5 },
                        React.createElement("h1", null, "Welcome"),
                        React.createElement(Image, { width: 70, height: 70, src: customLogo ? customLogo : '/images/twin-logo-white.svg' }),
                        React.createElement(Stack, { gap: 2, className: 'mx-3' },
                            React.createElement(FloatingLabel, { controlId: "floatingInput", label: "Email address" },
                                React.createElement(Form.Control, { value: email, type: "email", placeholder: "name@example.com", className: 'bg-white text-black', onChange: function (e) { return setEmail(e.target.value); }, disabled: isError })),
                            React.createElement(FloatingLabel, { controlId: "floatingPassword", label: "Password" },
                                React.createElement(Form.Control, { value: password, type: "password", placeholder: "Password", className: 'bg-white text-black', onChange: function (e) { return setPassword(e.target.value); }, disabled: isError }))),
                        React.createElement(Button, { className: 'py-2 px-5 pill-shape', disabled: isError, onClick: login }, "LOGIN"),
                        React.createElement("small", { className: 'clickable', onClick: props.reset }, "Forgot your password?")))),
            React.createElement(Row, null,
                React.createElement(Stack, { className: 'align-items-center mt-5' },
                    React.createElement("span", null,
                        React.createElement("small", null, "\u00A9 2023 TwinLogic PTE Ltd. All rights reserved."))))),
        React.createElement(Alert, { show: isError, variant: 'danger', className: 'fixed-top', dismissible: true, onClose: function () { setIsError(false); } },
            React.createElement(Alert.Heading, null, "Oops! Something went wrong"),
            React.createElement("p", null, "Login is unsuccessful. Email address and/or password do not match with our records.")),
        React.createElement(Alert, { show: isTemp, variant: 'info', className: 'fixed-top', dismissible: true, onClose: function () { goTo(nextPage); } },
            React.createElement(Alert.Heading, null, "You are using a Temporary Password"),
            React.createElement("p", null, "Login is successful. However, your temporary password will expire soon. Please change your password in Profile Settings.")));
}
function ShowPage() {
    var _a = React.useState(false), resetPage = _a[0], setResetPage = _a[1];
    if (resetPage)
        return React.createElement(ResetPage, null);
    else
        return React.createElement(LoginPage, { reset: function () { return setResetPage(true); } });
}
var root = ReactDOM.createRoot(document.getElementById('main'));
root.render(React.createElement(ShowPage, null));
